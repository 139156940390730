import styled from '@emotion/styled';

export default styled.div`
  label: add-loading;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 100;
  align-items: center;
  justify-content: center;
  &.hidden {
    visibility: hidden;
    opacity: 0;
  }
  &.active {
    visibility: visible;
    opacity: 1;
  }
  > .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1;
  }
  > .icon {
    position: relative;
    z-index: 2;
  }
`;
