import styled from '@emotion/styled';

export default styled.header`
  label: header;
  font-family: 'Prompt', sans-serif;
  height: 70px;
  background: var(--color-black);
  display: flex;
  align-items: center;
  width: 100%;
  color: var(--color-white);
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  .logo img {
    max-width: 50px;
    max-height: 50px;
  }
  /* @media (max-width: 540px) {
    position: relative;
    margin-bottom: 20px;
  } */

  @media (max-width: 768px) {
    justify-content: space-between;
  }

  > .shop-info {
    flex: 1 1 auto;
    display: flex;

    > .logo {
      display: flex;
      align-items: center;
      > .name {
        margin-left: 10px;
        font-weight: 500;
        font-size: 20px;
        color: var(--color-white);
        white-space: nowrap;
      }
    }
    @media (max-width: 540px) {
      display: none;
    }
  }
  .wrap-search {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 20px;
    width: 100%;
    @media (max-width: 540px) {
      order: 1;
    }
  }
  > .customer-info {
    flex: 0 0 auto;
    @media (max-width: 540px) {
      flex: 1 1 auto;
      order: 0;
    }
    display: flex;
    align-items: center;
    margin-right: 20px;
    > .level {
      flex: 0 0 40px;
      margin-right: 10px;
      max-width: 40px;
      max-height: 40px;
    }
    @media (max-width: 540px) {
      > .info {
        flex: 0 0 auto;
      }
    }

    .customer-name {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 4px;
      max-width: 120px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .level-name {
      font-size: 12px;
      font-weight: 300;
      color: rgba(255, 255, 255, 0.7);
    }
  }
  > .languages {
    flex: 0 0 70px;
    display: flex;
    position: relative;
    @media (max-width: 540px) {
      order: 2;
    }

    > .select-languages {
      border: none;
      padding: 0px;
      .select-selected {
        padding: 0px 10px;
      }

      &.active {
        border-radius: 10px 10px 0 0;
        background-color: var(--color-dark);
        > .wrap-option {
          background-color: var(--color-dark);
          border-radius: 0 0 10px 10px;
          > .option {
            color: var(--color-white);
          }
        }
      }
      > .select-selected > span {
        color: var(--color-white);
      }
    }
  }
  > .cart {
    flex: 0 0 auto;
    margin-left: 20px;
    @media (max-width: 768px) {
      position: fixed;
      bottom: 14px;
      right: 20px;
      z-index: 90;
      margin-left: 0px;
    }
  }
`;
