import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal-es';
import map from 'lodash/map';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';

import { closeModal, openModal } from '../../utils/modal';

import Icon from '../Icon';
import IconButton from '../IconButton';
import Image from '../Image';
import Button from '../Button';

import { useLanguage } from '../../hooks/language';
import { getStreamer } from '../../service';

import Style from './style';

const ModalSteamer = (props) => {
  const { name, value, onChange } = props;

  const { getContentWithLanguage } = useLanguage();

  const [steamer, setSteamer] = useState([]);
  const [steamerSelected, updateSteamerSelected] = useState({});
  const [filterValue, setFilterValue] = useState('');

  useEffect(() => {
    const loadData = async () => {
      try {
        const res = await getStreamer();
        setSteamer(res);
      } catch (err) {
        setSteamer([]);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    updateSteamerSelected(value);
  }, [value]);

  const handleSelectItem = (value) => {
    onChange(value);
    closeModal(name);
  };

  const handleRemove = () => {
    onChange({});
  };

  const handleFilter = (e) => {
    setFilterValue(e.target.value);
  };

  const description = {
    en: 'Sunt sit anim sit voluptate mollit eu sit amet.',
    th: 'ทางร้านจะนำกำไรส่วนหนึ่ง สนับสนุนให้สตรีมเมอร์เกมที่คุณชอบโดยไม่มีค่าใช้จ่ายเพิ่มเติม',
  };

  const isPreview = !isEmpty(value);

  let steamerList = steamer;
  if (!isEmpty(filterValue)) {
    steamerList = filter(steamer, (obj) => {
      return includes(toLower(obj.fullname), toLower(filterValue));
    });
  }

  return (
    <>
      {isPreview ? (
        <div className="button-preview">
          <div className="steamer-info">
            <div className="wrap-image">
              <Image src={get(value, 'image')} />
            </div>
            <span className="ellipsis">{get(value, 'fullname')}</span>
          </div>
          <IconButton name="trash" type="danger" onClick={handleRemove} />
        </div>
      ) : (
        <Button
          onClick={() => {
            openModal(name);
            setFilterValue('');
          }}
        >
          <Icon name="gamepad" /> เลือกสตรีมเมอร์
        </Button>
      )}

      <Modal name={name} center zIndex={99}>
        <Style>
          <div className="title">
            <span>สนับสนุน</span>
            <IconButton
              size="lg"
              name="times"
              onClick={() => closeModal(name)}
            />
          </div>
          <p>{getContentWithLanguage(description)}</p>
          <label className="wrap-input-search" htmlFor="input-filter-steamer">
            <input
              id="input-filter-steamer"
              onChange={handleFilter}
              value={filterValue}
            />
            <button>
              <Icon name="search" />
            </button>
          </label>
          <div className="wrap-steamer">
            <ul>
              {isEmpty(steamerList) && (
                <li className="steamer-not-fount">
                  {getContentWithLanguage({
                    th: 'ไม่พบข้อมูล',
                    en: 'Data not found.',
                  })}
                </li>
              )}
              {map(steamerList, (value, index) => {
                const isActive = get(steamerSelected, 'id') === value.id;
                return (
                  <li key={index}>
                    <button
                      onClick={() => handleSelectItem(value)}
                      className={isActive ? 'active' : ''}
                    >
                      <div className="wrap-image">
                        <Image src={value.image} />
                      </div>
                      <div className="name">{value.fullname}</div>
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </Style>
      </Modal>
    </>
  );
};

ModalSteamer.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.shape(),
};

ModalSteamer.defaultProps = {
  onChange: () => null,
  value: {},
};

export default ModalSteamer;
