import styled from '@emotion/styled';

export default styled.div`
  label: search-product;
  max-width: 400px;
  width: 100%;
  position: relative;
  .input-search {
    width: 100%;
    &.open > label {
      border-radius: 18px 18px 0 0;
    }
    > label {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 8px 10px 8px 14px;
      border-radius: 18px;
      background-color: #3d3d3d;
      color: #ccc;
      input {
        outline: none;
        border: none;
        padding: 0;
        background: none;
        margin-left: 10px;
        width: 100%;
        color: #fff;
      }
      button,
      .loading {
        border: none;
        outline: none;
        background: none;
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        padding: 0;
      }
    }

    .button-cancle {
      display: none;
      font-family: Prompt;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
      background: none;
      border: none;
      outline: none;
      margin-left: 20px;
    }
  }
  .wrap-options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #fff;
    max-height: 300px;
    z-index: 2;
    border-radius: 0 0 18px 18px;
    background-color: #f5f5f5;
    overflow: auto;
    .title {
      font-family: Prompt;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
      margin: 20px 25px;
    }
    ul > li {
      padding: 5px 20px;
    }
    a {
      cursor: pointer;
      padding: 5px;
      display: flex;
      align-items: center;
      color: #4a4a4a;
      border-radius: 10px;
      .wrap-image {
        max-width: 60px;
        max-height: 60px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        border-radius: 10px;
      }
      .name,
      .description {
        font-family: Prompt;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4a4a4a;
      }
      &:hover {
        background: #fff;
        box-shadow: 0 4px 10px -4px rgba(0, 0, 0, 0.1);
      }
    }
  }
  .overlay {
    position: fixed;
    z-index: 0;
    background: #000;
    opacity: 0;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
  }
  .empty-options {
    display: none;
  }
  @media (max-width: 768px) {
    display: flex;
    justify-content: flex-end;
    .wrap-search-product {
      position: fixed;
      z-index: 9;
      background: #fff;
      top: 70px;
      width: 100vw;
      height: calc(100vh - 70px);
      left: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      .input-search {
        background: #ccc;
        width: 100%;
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        > label {
          max-width: 270px;
          background-color: #f5f5f5;
          color: #4a4a4a;
          input {
            color: #4a4a4a;
          }
        }
        &.open > label {
          border-radius: 18px;
        }
        .button-cancle {
          display: inline-block;
        }
      }
      .overlay {
        display: none;
      }
      .wrap-options {
        position: relative;
        top: 0;
        background: none;
        max-height: 100%;
      }
      .empty-options {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        .icon {
          width: 70px;
          height: 70px;
          font-family: Prompt;
          font-size: 36px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
          border-radius: 10px;
          background-color: #e5e5e5;
        }
        p {
          font-family: Prompt;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #4a4a4a;
          text-align: center;
        }
      }
    }
  }
  .button-search {
    display: none;
    border: none;
    background: none;
    outline: none;
    @media (max-width: 768px) {
      display: inline-block;
    }
  }
`;
