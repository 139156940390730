/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';

import Icon from '../Icon';

import Style from './style';

const Select = ({ options, value, onChange, className, placeholder }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [currentValue, updateCurrentValue] = React.useState({});

  React.useEffect(() => {
    updateCurrentValue(find(options, (option) => option.value === value));
  }, [value]); // eslint-disable-line

  const handleOpenOptions = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleSelectOption = (e, data) => {
    e.preventDefault();
    onChange(data.value);
    setIsOpen(false);
    updateCurrentValue(data);
  };

  const handleCloseSelect = () => {
    setIsOpen(false);
  };

  return (
    <Style className={`${className} ${isOpen ? 'active' : ''}`}>
      <a
        href="#"
        className="select-selected"
        onClick={handleOpenOptions}
        onBlur={handleCloseSelect}
      >
        <span>{get(currentValue, 'label', placeholder)}</span>
        <Icon name="caret-up" />
      </a>
      <div className="wrap-option">
        {map(options, (option) => (
          <a
            href="#"
            key={option.value}
            className={`option ${
              get(currentValue, 'value') === option.value ? 'selected' : ''
            }`}
            onClick={(e) => handleSelectOption(e, option)}
          >
            {option.label}
          </a>
        ))}
      </div>
    </Style>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

Select.defaultProps = {
  value: '',
  className: '',
  placeholder: 'Select ..',
  onChange: () => null,
};

export default Select;
