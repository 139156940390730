import React, { useEffect } from 'react';
import { hiddenLoading } from '../components/Loading';

export default () => {
  useEffect(() => {
    hiddenLoading();
  }, []);

  return <h1>Page not found.</h1>;
};
