import styled from '@emotion/styled';

export default styled.div`
  label: page-categories;
  font-family: 'Prompt', sans-serif;
  .catalog-item {
    margin-bottom: 46px;
    display: flex;
    justify-content: center;
    @media (max-width: 540px) {
      margin-bottom: 20px;
      display: block;
    }
  }
  .wrap-banner {
    margin-bottom: 30px;
    @media (max-width: 540px) {
      padding: 0;
    }
  }
  .wrap-slide-banner .slide-item {
    max-height: 516px;
    button {
      cursor: default;
      padding: 0;
      margin: 0;
      border: none;
      background: none;
      outline: none;
      border-radius: 20px;
      overflow: hidden;
      @media (max-width: 540px) {
        border-radius: 0;
      }
      &.link {
        cursor: pointer;
      }
    }
  }
  .wrap-category {
    > .header {
      margin-bottom: 50px;
      @media (max-width: 540px) {
        margin-bottom: 27px;
      }
      span {
        font-family: Prompt;
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4a4a4a;
      }
    }
  }
`;
