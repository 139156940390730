const base = process.env.REACT_APP_BASE_URL;

export const CATEGORY = `${base}/api/category`;
export const SHOP = `${base}/api/shop`;
export const CUSTOMER = `${base}/api/customer`;
export const SUB_CATEGORY = `${base}/api/subcategory`;
export const PRODUCT = `${base}/api/product`;
export const STREAMER = `${base}/api/streamer`;
export const ORDER = `${base}/api/order`;
export const SHOP_IS_OPEN = `${base}/api/shop/open`;
export const VALIDATE_COUPON = `${base}/api/coupon/validate`;
export const BANNER = `${base}/api/banner`;
export const FLASH_SALE = `${base}/api/flash-sale`;
export const SEARCH_PRODUCT = `${base}/api/products/search`;
export const ORDER_QUEUE = `${base}/api/orders/queue`;
