import styled from '@emotion/styled';

export default styled.div`
  label: caet;
  font-family: 'Prompt', sans-serif;
  > .icon-cart button {
    display: inline-block;
    width: 70px;
    height: 50px;
    border-radius: 10px;
    box-shadow: 0 4px 15px -5px var(--color-secondary);
    background-color: var(--color-dark);
    border: none;
    outline: none;
    position: relative;
    transition: all 0.2s;
    &:hover {
      background: var(--color-primary-hover);
    }
    > .count {
      position: absolute;
      top: 6px;
      right: 10px;
      width: 20px;
      height: 20px;
      box-shadow: 0 2px 3px 0 rgba(0, 151, 193, 0.6);
      background-color: var(--color-primary);
      color: var(--color-white);
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
    }
    > i {
      font-size: 28px;
      color: var(--color-white);
    }
  }
  > .cart-detail {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 95;
    justify-content: flex-end;
    visibility: hidden;
    opacity: 0;
    color: var(--color-secondary);
    transition: all 0.4s ease-in-out;
    #cart-overlay {
      position: absolute;
      z-index: 1;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.8);
      outline: none;
      border: none;
      cursor: default;
    }

    > .wrap-content {
      position: relative;
      z-index: 2;
      display: block;
      width: 400px;
      background-color: var(--color-light-grey);
      transform: translateX(100%);
      transition: all 0.4s ease-in-out;
      @media (max-width: 540px) {
        width: calc(100vw - 70px);
      }
    }
    &.active {
      visibility: visible;
      opacity: 1;
      > .wrap-content {
        transform: translateX(0);
      }
    }
    .head {
      display: flex;
      justify-content: space-between;
      padding: 20px 20px 0 20px;
      background: var(--color-white);
      h2 {
        font-size: 24px;
        font-weight: 500;
        color: var(--color-secondary);
        padding-bottom: 20px;
      }
    }
    .product-empty {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex: 1 1 auto;
      height: 100%;

      background: var(--color-white);
      > span {
        opacity: 0.6;
        font-size: 16px;
        font-weight: 500;
      }
      > i {
        font-size: 60px;
        margin-bottom: 10px;
        opacity: 0.6;
      }
    }
    .products {
      padding: 0 20px;
      height: calc(100vh - 410px);
      background: var(--color-white);
      @media (max-width: 540px) {
        height: calc(100vh - 364px);
        padding: 0 10px;
      }

      overflow: auto;
    }
    .products ul > li {
      display: flex;
      background-color: var(--color-light-grey);
      padding: 10px;
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 10px;
      > .wrap-image {
        flex: 0 0 80px;
        margin-right: 8px;
        border-radius: 10px;
        overflow: hidden;
      }
      > .content {
        flex: 1 1 auto;
        width: 100%;
        > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0;
          padding: 0;
          &.info {
            align-items: flex-start;
            margin-bottom: 8px;
          }
          .sub-category,
          .price {
            display: block;
            font-size: 14px;
            font-weight: 500;
            color: var(--color-secondary);
            .old {
              font-size: 12px;
              color: rgba(74, 74, 74, 0.7);
              text-decoration: line-through;
            }
            .sale {
              color: #e02020;
            }
          }
          .product-name {
            display: block;
            font-size: 10px;
            font-weight: 300;
            color: var(--color-secondary);
            margin-top: 6px;
          }
        }
      }
    }
    .summary {
      padding: 20px;
      > ul > li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
        > span {
          font-size: 16px;
          font-weight: 600;
          color: var(--color-secondary);
        }
        > .wrap-button {
          flex: 0 0 250px;
          @media (max-width: 540px) {
            flex: 0 0 180px;
          }
          > button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 50px;
            @media (max-width: 540px) {
              height: 40px;
            }
            > i {
              margin-right: 6px;
            }
          }
          .button-preview {
            display: flex;
            overflow: hidden;
            align-items: center;
            justify-content: space-between;
            padding: 6px 16px;
            font-size: 16px;
            border-radius: 4px;
            border-radius: 10px;
            background: var(--color-white);
            color: var(--color-secondary);
            box-shadow: 0 4px 10px -5px rgba(0, 0, 0, 0.2);
            height: 50px;
            width: 250px;
            @media (max-width: 540px) {
              padding: 6px 10px;
            }
            > button {
              flex: 0 0 24px;
              margin-left: 6px;
            }
            @media (max-width: 540px) {
              height: 40px;
              width: 180px;
            }
            .ellipsis {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
            .steamer-info,
            .discount-info {
              display: flex;
              align-items: center;
              width: 180px;
              @media (max-width: 540px) {
                width: 126px;
              }
              .wrap-image {
                flex: 0 0 30px;
                display: inline-block;
                height: 30px;
                overflow: hidden;
                border-radius: 100%;
                margin-right: 6px;
                @media (max-width: 540px) {
                  display: none;
                }
              }
            }
            .discount-info {
              justify-content: space-between;
              > .price {
                color: var(--color-primary);
                white-space: nowrap;
              }
            }
          }
        }
      }
      .total-amount {
        margin: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 20px;
        color: var(--color-primary);
        font-weight: 500;
      }
      button.submit-order {
        display: block;
        width: 100%;
        height: 50px;
        > i {
          margin-right: 6px;
        }
      }
    }
  }
`;

export const StyleModalConfirm = styled.div`
  > p {
    margin: 30px 0;
    color: var(--color-secondary);
    font-size: 16px;
    line-height: 1.25;
  }
  > button {
    width: 100%;
    height: 50px;
    > i {
      margin-right: 6px;
    }
  }
`;
