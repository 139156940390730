import React from 'react';

import { Route, Switch } from 'react-router-dom';

import Home from './pages/Home';
import Products from './pages/Products';
import ProductDetail from './pages/ProductDetail';
import NoMatch from './pages/404';
import Thank from './pages/Thank';

export default () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/products/:id" component={Products} />
      <Route exact path="/products/detail/:id/:productId?" component={ProductDetail} />
      <Route exact path="/thank" component={Thank} />
      <Route component={NoMatch} />
    </Switch>
  );
};
