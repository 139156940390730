import styled from '@emotion/styled';

export default styled.div`
  label: modal-steamer;
  font-family: 'Prompt', sans-serif;
  width: 450px;
  border-radius: 20px;
  background-color: var(--color-white);
  padding: 20px;
  @media (max-width: 540px) {
    width: 300px;
  }
  > .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > span {
      font-size: 20px;
      font-weight: 500;
      color: var(--color-secondary);
    }
    > button > i {
      font-size: 20px;
    }
  }
  > p {
    color: var(--color-secondary);
    opacity: 0.7;
    line-height: 1.5;
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .wrap-input-search {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    margin-bottom: 18px;
    border-radius: 4px;
    overflow: hidden;
    padding: 0 10px;
    > input {
      width: 100%;
      height: 36px;
      border: none;
      outline: none;
    }
    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      border: none;
      outline: none;
      background: none;
    }
  }

  > .wrap-steamer > ul {
    padding: 10px;
    border-radius: 20px;
    background-color: var(--color-light-grey);
    display: block;
    color: var(--color-secondary);
    max-height: 380px;
    overflow: auto;
    > li {
      display: block;
      width: 100%;
      margin-bottom: 10px;
      &.steamer-not-fount {
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    li > button {
      outline: none;
      width: 100%;
      padding: 10px;
      border-radius: 10px;
      box-shadow: 0 4px 9px -5px rgba(0, 0, 0, 0.2);
      background-color: var(--color-white);
      border: none;
      display: flex;
      align-items: center;

      &:hover {
        color: var(--color-primary);
      }
      &.active {
        background: var(--color-primary);
        color: var(--color-white);
      }
      > .wrap-image {
        display: inline-block;
        width: 60px;
        height: 60px;
        margin-right: 10px;
        overflow: hidden;
        border-radius: 100%;
      }
      .name {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
`;
