import * as ActionType from '../actions/actionType';

const innitialState = {
  isInitApp: false,
  shop: {},
  customer: {},
  queue: {},
  shopBy: {},
};

export default (state = innitialState, action) => {
  switch (action.type) {
    case ActionType.INITAPP: {
      return {
        ...state,
        shop: action.shop,
        customer: action.customer,
        queue: action.queue,
        shopBy: action.shopBy,
        isInitApp: true,
      };
    }
    case ActionType.ORDER_QUEUE: {
      return {
        ...state,
        queue: action.queue,
      };
    }
    default: {
      return state;
    }
  }
};
