import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import moment from 'moment';

import IconSuccess from '../../images/ico-success.png';
import { hiddenLoading } from '../../components/Loading';

import { connectRedux } from '../../utils';

import Style from './style';

const Thank = ({ operator, order }) => {
  React.useEffect(() => {
    hiddenLoading();
    // eslint-disable-next-line
  }, []);

  return (
    <Style>
      <div className="container">
        <img src={IconSuccess} alt="" />
        <h1>ยืนยันการสั่งซื้อสำเร็จ</h1>
        <p>
          ระบบจะสรุปข้อมูลการซื้อและช่องทาง <br /> การชำระเงินทางแชท {operator}{' '}
          ของคุณ
          <br />
          กรุณาปิดหน้าเว็บนี้
        </p>
        <p className="remark">
          กรุณาส่งหลักฐานการโอนเงินก่อน{' '}
          {`${moment(get(order, 'createAt')).format('DD/MM/YYYY')} 23:59`} นะคะ
        </p>
        <p className="remark">
          คุณสามารถตรวจสอบคิวของคุณได้หลังจากที่ทำการโอนเงินแล้ว โดยการเข้าเช็คผ่านปุ่ม go to shop ได้เลยค่ะ
        </p>
      </div>
    </Style>
  );
};

Thank.propTypes = {
  operator: PropTypes.string.isRequired,
  order: PropTypes.shape().isRequired
};

const mapState = ({ app, orders }) => ({
  operator: get(app, 'shop.operator', ''),
  order: get(orders, 'orderSuccess', {})
});

export default connectRedux(mapState)(Thank);
