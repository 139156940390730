/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { Link, withRouter } from 'react-router-dom';
import { useLanguage } from '../../hooks/language';

import Image from '../../components/Image';
import Cart from '../Cart';
import Select from '../../components/Select';
import Search from '../Search';

import { connectRedux } from '../../utils';

import Style from './style';

const Header = (props) => {
  const { shop, customer, location } = props;
  const { switchLanguage, languages, currentLang } = useLanguage();

  const handleSelectLanguage = (value) => {
    switchLanguage(value);
  };

  const isHiddenCart = get(location, 'pathname') === '/thank';

  return (
    <Style>
      <div className="shop-info">
        <Link className="logo" to="/">
          <Image src={get(shop, 'logo')} />
          <div className="name">{get(shop, 'name')}</div>
        </Link>
      </div>
      <div className="wrap-search">
        <Search />
      </div>

      <div className="customer-info">
        <div className="level">
          <Image src={get(customer, 'level.image')} />
        </div>
        <div className="info">
          <div className="customer-name">HI {get(customer, 'fullname')}</div>
          <div className="level-name">{get(customer, 'level.name')}</div>
        </div>
      </div>
      <div className="languages">
        <Select
          value={currentLang}
          className="select-languages"
          onChange={handleSelectLanguage}
          options={languages.map((language) => ({
            label: language.label,
            value: language.key
          }))}
        />
      </div>
      {!isHiddenCart && (
        <div className="cart">
          <Cart />
        </div>
      )}
    </Style>
  );
};

Header.propTypes = {
  shop: PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.string
  }),
  customer: PropTypes.shape({
    fullname: PropTypes.string,
    level: PropTypes.shape()
  }),
  location: PropTypes.shape()
};

Header.defaultProps = {
  shop: {},
  customer: {},
  location: {}
};

const mapState = ({ app }) => {
  return {
    shop: app.shop,
    customer: app.customer
  };
};

export default connectRedux(mapState)(withRouter(Header));
