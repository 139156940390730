import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal-es';
import isEmpty from 'lodash/isEmpty';

import { closeModal, openModal } from '../../utils/modal';

import Icon from '../Icon';
import IconButton from '../IconButton';
import Button from '../Button';

import Style from './style';

const ModalNote = (props) => {
  const { name, value, onChange } = props;

  const [currentValue, updateValue] = useState('');
  useEffect(() => {
    updateValue(value);
  }, [value]);

  const handleSubmit = () => {
    onChange(currentValue);
    closeModal(name);
  };

  const isPreview = !isEmpty(value);
  return (
    <>
      {isPreview ? (
        <div className="button-preview">
          <span className="ellipsis">{value}</span>
          <IconButton name="edit" onClick={() => openModal(name)} />
        </div>
      ) : (
        <Button onClick={() => openModal(name)}>
          <Icon name="sticky-note" /> เพิ่มข้อความ
        </Button>
      )}
      <Modal name={name} center zIndex={99}>
        <Style>
          <div className="title">
            <span>เพิ่มข้อความ</span>
            <IconButton
              size="lg"
              name="times"
              onClick={() => closeModal(name)}
            />
          </div>
          <textarea
            value={currentValue}
            onChange={(e) => updateValue(e.target.value)}
            name="note"
            rows="6"
            placeholder="กรุณาพิมพ์ข้อความ"
          />
          <Button
            className="button-submit"
            type="primary"
            onClick={handleSubmit}
          >
            <Icon name="save" /> บันทึก
          </Button>
        </Style>
      </Modal>
    </>
  );
};

ModalNote.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

ModalNote.defaultProps = {
  value: '',
  onChange: () => null,
};

export default ModalNote;
