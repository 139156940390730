import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import Routers from './Routers';

import { initApp } from './actions/action';
import Header from './containers/Header';
import Footer from './components/Footer';
import Loading from './components/Loading';
import OrderQueue from './components/OrderQueue';
import { ModalProvider } from './utils/modal';

import { connectRedux } from './utils';
import Language from './hooks/language';

const languages = [
  { label: 'TH', key: 'th' },
  { label: 'EN', key: 'en' }
];

const App = (props) => {
  const { dispatch, isInitApp, shop, queue } = props;
  const history = useHistory();
  const [currentLang, setCurrentLang] = useState('th');

  useEffect(() => {
    try {
      const url = new URL(window.location.href);
      let data = url.searchParams.get('r');
      if (process.env.NODE_ENV === 'development') {
        // data = 'eyJjdXN0b21lcklkIjoiVTNkZDczNzhkZDhkYTAzMDdjN2RhNWY4NGE5ZTgxOWNiXzE2NTQ5NTA1NzkiLCJzaG9wSWQiOiIxNjU0OTUwNTc5Iiwib3BlcmF0b3IiOiJMSU5FIn0='; // uat
        data = 'eyJjdXN0b21lcklkIjoiVTNkZDczNzhkZDhkYTAzMDdjN2RhNWY4NGE5ZTgxOWNiXzE2NTQ5NTA1NzkiLCJzaG9wSWQiOiIxNjU0OTUwNTc5Iiwib3BlcmF0b3IiOiJMSU5FIiwiaXNBZG1pbiI6dHJ1ZX0='; // uat form admin
        // data = 'eyJjdXN0b21lcklkIjoiRkIwOTQ5OTM0MyIsInNob3BJZCI6IjExMTE0MjY5Mzk3NDE1MCIsIm9wZXJhdG9yIjoiRkFDRUJPT0sifQ==';
      }

      dispatch(initApp(data));
      /* set color primary */
    } catch (err) {
      console.log('err', err);
    }
    history.replace('/');
  }, []); // eslint-disable-line

  useEffect(() => {
    setCurrentLang(get(shop, 'language', 'th'));
    document.documentElement.style.setProperty(
      '--color-primary',
      get(shop, 'color', '#0097c1')
    );
    document.documentElement.style.setProperty(
      '--color-primary-hover',
      get(shop, 'colorHover', '#0281a4')
    );
  }, [get(shop, 'id')]); // eslint-disable-line

  const switchLanguage = (value) => {
    setCurrentLang(value);
  };

  const getContentWithLanguage = (value) => {
    return get(value, currentLang, '');
  };

  let logo = `${process.env.REACT_APP_IMG_URL}/${get(shop, 'logo')}`;
  if (/^http:+/.test(get(shop, 'logo'))) logo = get(shop, 'logo');

  return (
    <Language.Provider
      value={{
        currentLang,
        switchLanguage,
        getContentWithLanguage,
        languages
      }}
    >
      <ModalProvider>
        <Loading />
        {isInitApp && !isEmpty(shop) && (
          <>
            <Helmet>
              <meta charSet="utf-8" />
              <title>{get(shop, 'name')}</title>
              <link rel="icon" type="image/png" sizes="96x96" href={logo} />
            </Helmet>
            <Header />
            {!isEmpty(get(queue, 'orderId')) && <OrderQueue />}
            <div className="wrap-page" id="WRAP-PAGE" style={{ opacity: 0 }}>
              <Routers />
            </div>
            <Footer />
          </>
        )}
        <ToastContainer position="top-center" autoClose={5000} />
      </ModalProvider>
    </Language.Provider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isInitApp: PropTypes.bool.isRequired,
  shop: PropTypes.shape().isRequired,
  queue: PropTypes.shape().isRequired,
};

const mapState = ({ app }) => ({
  isInitApp: app.isInitApp,
  shop: app.shop,
  queue: app.queue,
});

export default connectRedux(mapState)(App);
