import React from 'react';
import PropTypes from 'prop-types';

import './fontawesome-5.13.1/css/all.css';

const Icon = ({ name, className }) => (
  <i className={`fas fa-${name} ${className}`} />
);

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};
Icon.defaultProps = {
  className: '',
};
export default Icon;
