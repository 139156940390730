import styled from '@emotion/styled';

export default styled.div`
  label: catlog;
  font-family: 'Prompt', sans-serif;
  max-width: 210px;
  .wrap-image {
    text-decoration: none;
    width: 210px;
    height: 210px;
    overflow: hidden;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.2);
    background-color: var(--color-white);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .4s;
    &:hover {
      box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.2);
      img {
        transform: scale(1.1);
      }
    }
    img {
      max-width: 100%;
      max-height: 100%;
      transition: all .4s;
    }
    @media (max-width: 768px) {
      width: 100%;
      height: 100%;
    }
    @media (max-width: 375px) {
      width: 140px;
      height: 140px;
    }
  }

  .name {
    display: block;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: normal;
    color: var(--color-secondary);
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  .price {
    display: block;
    font-size: 14px;
    line-height: normal;
    letter-spacing: normal;
    color: var(--color-secondary);
    font-weight: 300;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
  .disabled {
    pointer-events: none;
  }
`;
