import styled from '@emotion/styled';

export default styled.div`
  label: button-qty;
  font-family: 'Prompt', sans-serif;
  display: flex;
  background: var(--color-white);
  overflow: hidden;
  border-radius: 10px;
  align-items: center;
  box-shadow: 0 4px 9px -5px rgba(0, 0, 0, 0.2);
  &.sm {
    max-width: 120px;
    height: 35px;
    @media (max-width: 320px) {
      max-width: 100px;
    }
    button {
      flex: 0 0 30px;
    }
  }
  &.lg {
    max-width: 140px;
    height: 50px;
    button {
      flex: 0 0 40px;
    }
  }
  button {
    background: none;
    border: none;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-secondary);
    outline: none;
    padding: 0px;
    &:disabled {
      opacity: 0.5;
    }
  }
  input {
    text-align: center;
    color: var(--color-primary);
    background: none;
    border: none;
    outline: none;
    width: 100%;
    flex: 1 1 auto;
    height: 40px;
    font-weight: bold;
    min-width: 0;
    &:focus {
      background-color: var(--color-light-grey);
    }
  }
`;
