/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import debounce from 'lodash/debounce';
import { Link } from 'react-router-dom';

import Style from './style';

import Image from '../../components/Image';
import Icon from '../../components/Icon';

import { connectRedux } from '../../utils';
import { searchProduct } from '../../service';
import { useLanguage } from '../../hooks/language';

const Search = (props) => {
  const { shop } = props;
  const shopId = get(shop, 'id');
  const [value, setValue] = React.useState('');
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const { getContentWithLanguage } = useLanguage();
  const [isOpen, setIsOpen] = React.useState(false);

  const fetchRef = React.useRef(0);

  const debounceTimeout = 500;

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      console.log('value >>>', value);
      if (!isEmpty(value)) {
        setOptions([]);
        setFetching(true);

        searchProduct(value, shopId).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
          }

          setOptions(newOptions);
          setFetching(false);
        });
      }
    };
    return debounce(loadOptions, debounceTimeout);
  }, [debounceTimeout]); // eslint-disable-line

  React.useEffect(() => {
    setIsOpen(document.body.offsetWidth > 768);
  }, []);

  const handleClearData = () => {
    if (document.body.offsetWidth <= 768 && isOpen) {
      setIsOpen(false);
    }
    setValue('');
    setOptions([]);
  };

  const isOpenOptions = !isEmpty(options);
  return (
    <Style>
      <button
        className="button-search"
        onClick={() => {
          setIsOpen(true);
          setTimeout(() => {
            try {
              document.getElementById('input-search').focus();
            } catch (err) {
              console.error(err);
            }
          }, 500);
        }}
      >
        <Icon name="search" />
      </button>
      {isOpen && (
        <div className="wrap-search-product">
          <div className={`input-search ${isOpenOptions ? 'open' : ''}`}>
            <label htmlFor="input-search">
              <Icon name="search" />
              <input
                type="text"
                id="input-search"
                autoComplete="off"
                value={value}
                onChange={(e) => {
                  debounceFetcher(e.target.value);
                  if (!fetching) {
                    setValue(e.target.value);
                  }
                }}
              />
              {!isEmpty(value) && !fetching && (
                <button
                  onClick={() => {
                    setValue('');
                  }}
                >
                  <Icon name="times-circle" />
                </button>
              )}
              {fetching && (
                <div className="loading">
                  <Icon name="spinner fa-spin" />
                </div>
              )}
            </label>
            <button className="button-cancle" onClick={handleClearData}>
              ยกเลิก
            </button>
          </div>
          {isOpenOptions && (
            <>
              <div className="wrap-options">
                <div className="title">Suggested</div>
                <ul>
                  {map(options, (data) => {
                    let to = `/products/${data.id}`;
                    if (data.type === 'subcategory') {
                      to = `/products/detail/${data.id}`;
                    }
                    return (
                      <li key={data.id}>
                        <Link
                          to={to}
                          onClick={handleClearData}
                          className="wrap-item"
                        >
                          <div className="wrap-image">
                            <Image src={data.image} />
                          </div>
                          <div className="info">
                            <div className="name">
                              {getContentWithLanguage(data.name)}
                            </div>
                            <div className="description">
                              {getContentWithLanguage(data.description)}
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="overlay" onClick={handleClearData} />
            </>
          )}
          {!isOpenOptions && (
            <div className="empty-options">
              <div className="icon">T</div>
              <p>พิมพ์เพื่อค้นหา</p>
            </div>
          )}
        </div>
      )}
    </Style>
  );
};

Search.propTypes = {
  shop: PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.string
  }),
  customer: PropTypes.shape({
    fullname: PropTypes.string,
    level: PropTypes.shape()
  })
};

Search.defaultProps = {
  shop: {},
  customer: {}
};

const mapState = ({ app }) => {
  return {
    shop: app.shop,
    customer: app.customer
  };
};

export default connectRedux(mapState)(Search);
