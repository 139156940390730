import { GET, POST } from './api';
import {
  CATEGORY,
  SHOP,
  CUSTOMER,
  SUB_CATEGORY,
  PRODUCT,
  STREAMER,
  ORDER,
  SHOP_IS_OPEN,
  VALIDATE_COUPON,
  BANNER,
  FLASH_SALE,
  SEARCH_PRODUCT,
  ORDER_QUEUE
} from './apiList';

export const getShop = (id) => GET(`${SHOP}/${id}`, 1500);
export const getCustomer = (id) => GET(`${CUSTOMER}/${id}`, 1500);
export const getCategory = (shopId, id = '') => GET(`${CATEGORY}/${shopId}/${id}`, 1500);
export const getSubCategory = (body) => POST(SUB_CATEGORY, body, 1500);
export const getProduct = (body) => POST(PRODUCT, body, 1500);
export const getStreamer = () => GET(STREAMER, 1500);
export const createOrder = (data) => POST(ORDER, data, 1500);
export const shopIsOpen = (id, role = '') => GET(`${SHOP_IS_OPEN}/${id}/${role}`);
export const validateCoupon = (body) => POST(VALIDATE_COUPON, body, 1000);
export const getBanner = (shopId) => GET(`${BANNER}/${shopId}`, {}, 0);
export const getFlashSale = (shopId, customerId) => GET(`${FLASH_SALE}/${shopId}/${customerId}`, 0);
export const searchProduct = (searcch, shopId) => GET(`${SEARCH_PRODUCT}/${searcch}/${shopId}`, 1000);
export const getOrderQueue = (id) => GET(`${ORDER_QUEUE}/${id}`);
