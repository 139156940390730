import styled from '@emotion/styled';

export default styled.button`
  label: button;
  display: inline-block;
  position: relative;
  overflow: hidden;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  padding: 0px;
  background-color: var(--color-dark-grey);
  color: var(--color-graphite);
  border: none;
  outline: none;
  transition: all 0.2s ease-in-out;
  > i {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
  }
  &.sm {
    width: 24px;
    height: 24px;
    > i {
      font-size: 14px;
    }
  }

  &:hover {
    background-color: var(--color-primary);
    color: var(--color-white);
  }
  &:active {
    transform: scale(0.8);
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &.primary {
    background: var(--color-primary);
    color: var(--color-white);
    &:hover {
      background-color: var(--color-primary-hover);
    }
  }
  &.danger {
    background-color: var(--color-dark-grey);
    color: var(--color-graphite);
    &:hover {
      color: var(--color-white);
      background-color: var(--color-danger-hover);
    }
  }
`;
