import styled from '@emotion/styled';

export default styled.div`
  label: select;
  display: block;
  width: 100%;
  position: relative;
  padding: 0px 6px;
  text-align: center;
  border: 1px solid var(--color-secondary);
  a {
    color: var(--color-secondary);
    outline: none;
    text-decoration: none;
    display: block;
  }
  .select-selected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    padding: 0 14px;
    > i {
      font-size: 20px;
      line-height: 0;
      transform: rotate(180deg);
    }
  }
  &.active {
    > .wrap-option {
      visibility: visible;
      opacity: 1;
      transform: translateY(0px);
    }
  }
  > .wrap-option {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-10px);
    transition: all .4s ease-in-out;
    max-height: 200px;
    overflow: auto;
    box-shadow: 0px 1px 0px 1px var(--color-secondary);
    border-top: none;
    > .option {
      padding: 10px;
      color: var(--color-secondary);
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background: var(--color-primary-hover);
        color: var(--color-white);
      }
      &.selected {
        background: var(--color-primary);
        color: var(--color-white);
      }
    }
  }
`;
