import styled from '@emotion/styled';

export default styled.footer`
  label: footer;
  font-family: 'Prompt', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background-color: var(--color-light-grey);
  color: var(--color-secondary);
  font-size: 14px;
  font-weight: 500;
  @media (max-width: 768px) {
    display: none;
  }
`;
