import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import map from 'lodash/map';

import { useHistory } from 'react-router-dom';

import Image from '../../components/Image';

const Banner = (props) => {
  const { items } = props;
  const history = useHistory();

  const handleClickLink = (link) => {
    const { origin } = window.location;
    const re = new RegExp(`^${origin}`, 'i');
    // console.log('re >>', re.test(link));
    // console.log('link', link, origin);
    if (/^\//.test(link) || re.test(link)) {
      history.push(link.replace(re, ''));
    } else {
      window.open(link, '_blank');
    }
  };

  if (items.length <= 0) return '';
  return (
    <div className="wrap-slide-banner">
      <Slider
        dots={false}
        arrows={false}
        // draggable={false}
        autoplay
        autoplaySpeed={8000}
        infinite
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
      >
        {map(orderBy(items, ['sort'], ['asc']), (item) => {
          const isLink = !isEmpty(get(item, 'link'));
          return (
            <div className="slide-item" key={get(item, '_id')}>
              <button
                className={isLink ? 'link' : ''}
                onClick={() => {
                  if (isLink) {
                    handleClickLink(get(item, 'link'));
                  }
                }}
              >
                <Image src={get(item, 'image', '')} />
              </button>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

Banner.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape())
};

Banner.defaultProps = {
  items: []
};

export default Banner;
