/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';

import Image from '../Image';

import Style from './style';

const Catalog = (props) => {
  const { name, price, image, onClick, disabled } = props;

  const handleSelect = (e) => {
    e.preventDefault();
    if (!disabled) onClick(e);
  };

  return (
    <Style className={disabled ? 'disabled' : ''}>
      <a href="#" className="wrap-image" onClick={handleSelect}>
        <Image src={image} />
      </a>
      <span className="name">{name}</span>
      <span className="price">{price}</span>
    </Style>
  );
};

Catalog.propTypes = {
  image: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  name: PropTypes.string.isRequired,
  price: PropTypes.string,
};

Catalog.defaultProps = {
  price: undefined,
  disabled: undefined,
  onClick: undefined,
};

export default Catalog;
