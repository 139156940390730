import map from 'lodash/map';
import get from 'lodash/get';
import find from 'lodash/find';
import filter from 'lodash/filter';

import * as ActionType from '../actions/actionType';

const innitialState = {
  products: [],
  note: '',
  steamer: {},
  discount: {},
  orderSuccess: {}
};

export default (state = innitialState, action) => {
  switch (action.type) {
    case ActionType.PRODUCTS_ADD: {
      const getProduct = find(
        state.products,
        (item) => item.id === get(action, 'data.id')
      );
      /* ถ้ามี product เดิมอยู่แล้ว ให้ update qty */
      if (getProduct) {
        const products = map(state.products, (item) => {
          if (item.id === get(action, 'data.id')) {
            return {
              ...item,
              qty: item.qty + get(action, 'data.qty', 0),
            };
          }
          return item;
        });
        return {
          ...state,
          products,
        };
      }
      return {
        ...state,
        discount: {},
        products: [...state.products, action.data],
      };
    }
    case ActionType.PRODUCTS_REMOVE: {
      return {
        ...state,
        discount: {},
        products: filter(
          state.products,
          (item) => item.id !== get(action, 'product.id')
        ),
      };
    }
    case ActionType.PRODUCTS_UPDATE_QTY: {
      const products = map(state.products, (item) => {
        if (item.id === get(action, 'product.id')) {
          return {
            ...item,
            qty: action.value,
          };
        }
        return item;
      });
      return {
        ...state,
        products,
        discount: {},
      };
    }
    case ActionType.ORDER_UPDATE: {
      return {
        ...state,
        [action.name]: action.value,
      };
    }
    case ActionType.SUBMIT_ORDER_SUCCAESS: {
      return {
        ...innitialState,
        orderSuccess: action.data,
      };
    }
    default: {
      return state;
    }
  }
};
