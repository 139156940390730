import React from 'react';

import Style from './style';

const ProductEmpty = () => {
  return (
    <Style>
      <div className="wrap-content">
        <div className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="76"
            height="76"
            viewBox="0 0 76 76"
          >
            <defs>
              <filter
                id="hxr9wh2twa"
                width="115.7%"
                height="115.7%"
                x="-7.9%"
                y="-5%"
                filterUnits="objectBoundingBox"
              >
                <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur
                  in="shadowOffsetOuter1"
                  result="shadowBlurOuter1"
                  stdDeviation="1.5"
                />
                <feColorMatrix
                  in="shadowBlurOuter1"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
              </filter>
              <circle id="0w502ni9ab" cx="35" cy="35" r="35" />
            </defs>
            <g fill="none" fillRule="evenodd">
              <g>
                <g>
                  <g>
                    <g transform="translate(-682 -339) translate(637 340) translate(48)">
                      <use
                        fill="#000"
                        filter="url(#hxr9wh2twa)"
                        xlinkHref="#0w502ni9ab"
                      />
                      <use fill="#FFF" xlinkHref="#0w502ni9ab" />
                    </g>
                    <path
                      fill="#CCC"
                      fillRule="nonzero"
                      d="M35 70c19.335 0 35-15.665 35-35S54.335 0 35 0 0 15.665 0 35s15.665 35 35 35zm0-6.774C19.433 63.226 6.774 50.566 6.774 35 6.774 19.433 19.434 6.774 35 6.774c15.567 0 28.226 12.66 28.226 28.226 0 15.567-12.66 28.226-28.226 28.226zM23.71 32.742c2.498 0 4.516-2.018 4.516-4.516s-2.018-4.516-4.516-4.516-4.516 2.018-4.516 4.516 2.018 4.516 4.516 4.516zm22.58 0c2.498 0 4.516-2.018 4.516-4.516s-2.018-4.516-4.516-4.516-4.516 2.018-4.516 4.516 2.018 4.516 4.516 4.516zm1.13 18.064c1.862 0 3.386-1.524 3.386-3.387s-1.524-3.387-3.387-3.387H22.581c-1.863 0-3.387 1.524-3.387 3.387s1.524 3.387 3.387 3.387h24.838z"
                      transform="translate(-682 -339) translate(637 340) translate(48)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className="message">ขออภัย ไม่พบสินค้า</div>
      </div>
    </Style>
  );
};

export default ProductEmpty;
