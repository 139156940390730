import React, { useState } from 'react';
import PropTypes from 'prop-types';

import imgNotFound from '../images/img-not-found.png';

const Image = (props) => {
  const { src } = props;

  const [image, setImage] = useState('');

  React.useEffect(() => {
    setImage(/^http:+/.test(src) ? src : `${process.env.REACT_APP_IMG_URL}/${src}`);
  }, [src]);

  const handleLoadImageError = () => {
    setImage(imgNotFound);
  };

  return <img {...props} src={image} alt="" onError={handleLoadImageError} />;
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
};

export default Image;
