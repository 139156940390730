import React from 'react';
import Style from './style';

import './icon.scss';

export default () => {
  return (
    <Style id="APP-LOADING">
      <div className="overlay" />
      <span className="icon" />
    </Style>
  );
};

export const openLoading = () => {
  const dom = document.getElementById('APP-LOADING');
  const domPage = document.getElementById('WRAP-PAGE');
  if (dom) {
    dom.classList.remove('hidden');
    dom.classList.add('active');
  }
  if (domPage) {
    domPage.style.opacity = 0;
  }
};

export const hiddenLoading = () => {
  const dom = document.getElementById('APP-LOADING');
  const domPage = document.getElementById('WRAP-PAGE');
  if (dom) {
    dom.classList.remove('active');
    dom.classList.add('hidden');
  }
  if (domPage) {
    domPage.style.opacity = 1;
  }
};
