import styled from '@emotion/styled';

export default styled.div`
  label: page-thank;
  font-family: 'Prompt', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 170px);
  @media (max-width: 540px) {
    min-height: calc(100vh - 100px);
  }
  > .container {
    text-align: center;
    > img {
      max-width: 80px;
      max-height: 80px;
      margin-bottom: 40px;
    }
    h1 {
      font-size: 24px;
      font-weight: 500;
      color: var(--color-secondary);
    }
    p {
      display: inline-block;
      max-width: 320px;
      font-size: 16px;
      font-weight: 300;
      color: var(--color-secondary);
      line-height: 1.5;
    }
  }
  p.remark {
    color: #f00;
    text-align: center;
    font-size: 16px;
    display: block;
    max-width: 100%;
    margin-bottom: 20px;
  }
`;
