import styled from '@emotion/styled';

export default styled.div`
  label: page-products;
  font-family: 'Prompt', sans-serif;
  .product-item {
    margin-bottom: 46px;
    display: flex;
    justify-content: center;
    @media (max-width: 540px) {
      margin-bottom: 20px;
      display: block;
    }
  }
`;
