import React from 'react';
import PropTypes from 'prop-types';

import Style from './style';

const Button = (props) => {
  const { children, type, onClick, disabled, className, style } = props;
  return (
    <Style
      style={style}
      disabled={disabled}
      className={` ${type} ${className}`}
      onClick={(e) => {
        if (!disabled) onClick(e);
      }}
    >
      {children}
    </Style>
  );
};

Button.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.shape(),
  type: PropTypes.oneOf(['default', 'primary', 'danger']),
};

Button.defaultProps = {
  disabled: undefined,
  onClick: () => null,
  children: '',
  type: 'default',
  className: '',
  style: undefined
};

export default Button;
