import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';

import Image from '../../components/Image';
import Icon from '../../components/Icon';
import IconButton from '../../components/IconButton';
import ButtonQTY from '../../components/ButtonQTY';
import Button from '../../components/Button';
import ModalNote from '../../components/ModalNote';
import ModalDiscount from '../../components/ModalDiscount';
import ModalSteamer from '../../components/ModalSteamer';
import ModalConfirm from '../../components/ModalConfirm';

import { closeModal, openModal } from '../../utils/modal';
import { useLanguage } from '../../hooks/language';
import { connectRedux, formatNumber } from '../../utils';
import {
  updateProductQTY,
  removeProduct,
  updateOrder,
  submitOrder
} from '../../actions/action';

import Style, { StyleModalConfirm } from './style';

const Cart = (props) => {
  const history = useHistory();
  const {
    products = [],
    dispatch,
    note,
    discount,
    steamer,
    totalAmount,
    productQTY,
    currency,
    customerId,
    shopId
  } = props;

  const { getContentWithLanguage } = useLanguage();
  const [isOpen, setOpenCart] = React.useState(false);

  const handleUpdateProductQTY = (product, value) => {
    dispatch(updateProductQTY(product, value));
  };

  const handleRemoveProducct = (product) => {
    dispatch(removeProduct(product));
  };

  const handleNoteChange = (value) => {
    dispatch(updateOrder(value, 'note'));
  };

  const handleDiscountChange = (value) => {
    dispatch(updateOrder(value, 'discount'));
  };

  const handleSteamerChange = (value) => {
    dispatch(updateOrder(value, 'steamer'));
  };

  const handleSubmitSuccess = () => {
    history.push('/thank');
  };

  const handleSubmit = () => {
    dispatch(submitOrder(handleSubmitSuccess));
    closeModal('confirm-submit-order');
    setOpenCart(false);
  };

  const isEmptyProduct = isEmpty(products);

  return (
    <Style>
      <div className="icon-cart">
        <button onClick={() => setOpenCart(true)}>
          <Icon name="shopping-cart" />
          <span className="count">{productQTY}</span>
        </button>
      </div>
      <div className={`cart-detail ${isOpen ? 'active' : ''}`}>
        <button id="cart-overlay" onClick={() => setOpenCart(false)} />
        <div className="wrap-content">
          <div className="head">
            <h2>รถเข็น</h2>
          </div>
          {isEmptyProduct && (
            <div className="product-empty">
              <Icon name="cart-plus" />
              <span>ยังไม่มีสินค้า</span>
            </div>
          )}
          {!isEmptyProduct && (
            <div className="products">
              <ul>
                {map(products, (product) => {
                  let price = product.price;
                  let isale = false;
                  if (!isEmpty(product.flashSalePrice)) {
                    isale = true;
                    price = product.flashSalePrice;
                  }
                  return (
                    <li key={product.id}>
                      <div className="wrap-image">
                        <Image src={get(product, 'subCategory.image')} />
                      </div>
                      <div className="content">
                        <div className="info">
                          <div>
                            <span className="sub-category">
                              {getContentWithLanguage(
                                get(product, 'subCategory.name')
                              )}
                            </span>
                            <span className="product-name">
                              {getContentWithLanguage(product.name)}
                            </span>
                          </div>
                          <IconButton
                            className="remove-product"
                            name="trash"
                            type="danger"
                            size="sm"
                            onClick={() => handleRemoveProducct(product)}
                          />
                        </div>
                        <div>
                          <div className="price">
                            {isale && (
                              <div className="old">
                                {formatNumber(
                                  get(product, 'price.amount'),
                                  get(product, 'price.currency')
                                )}
                              </div>
                            )}
                            <div className={`current ${isale ? 'sale' : ''}`}>
                              {formatNumber(
                                get(price, 'amount'),
                                get(price, 'currency')
                              )}
                            </div>
                          </div>
                          <div className="qty">
                            <ButtonQTY
                              value={product.qty}
                              onChange={(value) => {
                                handleUpdateProductQTY(product, value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {!isEmptyProduct && (
            <div className="summary">
              <ul>
                <li>
                  <span>ข้อความ</span>
                  <div className="wrap-button">
                    <ModalNote
                      name="modal-order-note"
                      value={note}
                      onChange={handleNoteChange}
                    />
                  </div>
                </li>
                <li>
                  <span>สนับสนุน</span>
                  <div className="wrap-button">
                    <ModalSteamer
                      name="modal-order-steamer"
                      value={steamer}
                      onChange={handleSteamerChange}
                    />
                  </div>
                </li>
                <li>
                  <span>ส่วนลด</span>
                  <div className="wrap-button">
                    <ModalDiscount
                      name="modal-order-discount"
                      value={discount}
                      data={{
                        products,
                        customerId,
                        shopId
                      }}
                      onChange={handleDiscountChange}
                    />
                  </div>
                </li>
              </ul>
              <div className="total-amount">
                <span>รวมราคา</span>
                <span>{formatNumber(totalAmount, currency)}</span>
              </div>
              <Button
                type="primary"
                className="submit-order"
                onClick={() => openModal('confirm-submit-order')}
              >
                <Icon name="cart" /> ยืนยันการสั่งซื้อ
              </Button>
            </div>
          )}
        </div>
      </div>
      <ModalConfirm title="ยืนยันการสั่งซื้อ" name="confirm-submit-order">
        <StyleModalConfirm>
          <p>คุณต้องการยืนยันการสั่งซื้อสินค้าหรือไม่</p>
          <Button onClick={handleSubmit} type="primary">
            <Icon name="check-circle" />
            ยืนยัน
          </Button>
        </StyleModalConfirm>
      </ModalConfirm>
    </Style>
  );
};

Cart.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape()),
  dispatch: PropTypes.func.isRequired,
  currency: PropTypes.string,
  note: PropTypes.string,
  customerId: PropTypes.string.isRequired,
  shopId: PropTypes.string.isRequired,
  totalAmount: PropTypes.number,
  productQTY: PropTypes.number,
  discount: PropTypes.shape(),
  steamer: PropTypes.shape()
};

Cart.defaultProps = {
  products: {},
  steamer: {},
  note: '',
  discount: {},
  totalAmount: 0,
  productQTY: 0,
  currency: ''
};

const mapState = ({ orders, app }) => {
  const totalProductAmount = reduce(
    orders.products,
    (sum, value) => {
      let price = value.price;
      if (!isEmpty(value.flashSalePrice)) {
        price = value.flashSalePrice;
      }
      return sum + price.amount * value.qty;
    },
    0
  );
  const productQTY = reduce(
    orders.products,
    (sum, value) => sum + value.qty,
    0
  );
  const totalAmount = totalProductAmount - get(orders, 'discount.price', 0);
  return {
    products: orders.products,
    note: orders.note,
    discount: orders.discount,
    steamer: orders.steamer,
    totalAmount: totalAmount <= 0 ? 0 : totalAmount,
    productQTY,
    currency: get(orders.products, '0.price.currency', ''),
    customerId: get(app, 'customer.id', ''),
    shopId: get(app, 'shop.id', '')
  };
};

export default connectRedux(mapState)(Cart);
