import { createContext, useContext } from 'react';

const Language = createContext(null);

const useLanguage = () => {
  return useContext(Language);
};

export default Language;

export { useLanguage };
