import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import { Link } from 'react-router-dom';
import Countdown from 'react-countdown';

import Style from './style';

import Image from '../../components/Image';
import Icon from '../../components/Icon';

import { connectRedux, formatNumber } from '../../utils';
import { getFlashSale } from '../../service';
import { useLanguage } from '../../hooks/language';

const FlashSale = (props) => {
  const { shop, customer } = props;
  const { getContentWithLanguage } = useLanguage();
  const [state, setState] = React.useState([]);
  const [isViewAll, setIsViewAll] = React.useState(false);

  const loadData = async () => {
    const res = await getFlashSale(get(shop, 'id'), get(customer, 'id'));
    setState(
      map(res, (item) => {
        return {
          ...item,
          isActive: true
        };
      })
    );
  };

  React.useEffect(() => {
    loadData();
  }, []); // eslint-disable-line

  const handleViewAll = () => {
    setIsViewAll(true);
    setState(
      map(state, (item) => ({
        ...item,
        isActive: item.timestamp > Date.now()
      }))
    );
  };

  const handleCountdownComplete = (id) => {
    setState(
      map(state, (item) => ({
        ...item,
        isActive: item.timestamp > Date.now() && item.id !== id
      }))
    );
  };

  const renderer = (data) => {
    const { days, hours, minutes, seconds } = data;
    return (
      <ul className="countdown">
        <li>
          <span className="value">{days}</span>
          <span className="label">DAYS</span>
        </li>
        <li>
          <span className="value">{hours}</span>
          <span className="label">HOURS</span>
        </li>
        <li>
          <span className="value">{minutes}</span>
          <span className="label">MINUTES</span>
        </li>
        <li>
          <span className="value">{seconds}</span>
          <span className="label">SECONDS</span>
        </li>
      </ul>
    );
  };

  const items = orderBy(
    filter(state, (data) => data.isActive),
    ['timestamp'],
    ['asc']
  );

  if (isEmpty(state)) return '';
  return (
    <Style>
      <div className="title">
        <span>Flash Sale</span>
        {!isViewAll && (
          <button className="view-all" type="button" onClick={handleViewAll}>
            ดูทั้งหมด
          </button>
        )}
      </div>
      <div className="wrap-item">
        <ul>
          {map(isViewAll ? items : items.slice(0, 3), (item) => {
            return (
              <li key={item.id}>
                <div className="img">
                  <Image src={item.image} alt="" />
                </div>
                <div className="info">
                  <div className="name">
                    {getContentWithLanguage(item.name)}
                  </div>
                  <div className="price">
                    <div className="old">
                      {formatNumber(
                        get(item, 'price.amount'),
                        get(item, 'price.currency')
                      )}
                    </div>
                    <div className="current">
                      {formatNumber(
                        get(item, 'flashSalePrice.amount'),
                        get(item, 'flashSalePrice.currency')
                      )}
                    </div>
                  </div>
                  <div className="wrap-countdown">
                    <Countdown
                      date={item.timestamp}
                      renderer={renderer}
                      onComplete={() => {
                        handleCountdownComplete(item.id);
                      }}
                    />
                    <Link
                      to={`/products/detail/${get(item, 'subcategory.id')}/${
                        item.id
                      }`}
                    >
                      <Icon name="shopping-cart" />
                    </Link>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </Style>
  );
};

FlashSale.propTypes = {
  shop: PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.string
  }),
  customer: PropTypes.shape({
    fullname: PropTypes.string,
    level: PropTypes.shape()
  })
};

FlashSale.defaultProps = {
  shop: {},
  customer: {}
};

const mapState = ({ app }) => {
  return {
    shop: app.shop,
    customer: app.customer
  };
};

export default connectRedux(mapState)(FlashSale);
