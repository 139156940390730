import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import map from 'lodash/map';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';

import { useLanguage } from '../../hooks/language';

import { connectRedux, alert, formatNumber } from '../../utils';
import Image from '../../components/Image';
import ButtonQTY from '../../components/ButtonQTY';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Breadcrumb from '../../components/Breadcrumb';
import { openLoading, hiddenLoading } from '../../components/Loading';

import { addProduct } from '../../actions/action';

import Style from './style';

import { getProduct } from '../../service';

const ProductDetail = (props) => {
  const { dispatch, shopId, customerId, match } = props;
  const { getContentWithLanguage } = useLanguage();
  const id = get(match, 'params.id', '');
  const productId = get(match, 'params.productId', '');

  const [products, setProducts] = useState([]);
  const [subCategory, setSubCategory] = useState({});
  const [productSelectd, setProductSelectd] = useState({ id: '', qty: 1 });
  const [isOpenModal, setOpenModal] = useState(false);
  const [isDisableSubmit, setIsDisableSubmit] = useState(true);

  const handleSelectProduct = (id) => {
    setProductSelectd({ id, qty: 1 });
    setIsDisableSubmit(false);
  };

  useEffect(() => {
    const initPage = async () => {
      openLoading();
      try {
        const body = {
          subCategoryId: id,
          shopId,
          customerId
        };
        const res = await getProduct(body);
        setSubCategory(res);
        const sortProducts = orderBy(res.products, ['sort'], ['asc']);
        setProducts(sortProducts);
        handleSelectProduct(
          !isEmpty(productId) ? productId : get(sortProducts, '0.id')
        );
        setOpenModal(!isEmpty(productId));
      } catch (err) {
        console.log('err >>', err);
      }
      hiddenLoading();
    };
    const reportWindowSize = () => {
      if (isDisableSubmit && window.innerWidth <= 540) {
        setIsDisableSubmit(false);
      }
    };

    window.addEventListener('resize', reportWindowSize);
    initPage();
    reportWindowSize();
    return () => {
      window.removeEventListener('resize', reportWindowSize);
    };
  }, [id]); // eslint-disable-line

  const handleChangeQTY = (value) => {
    setProductSelectd((values) => ({ ...values, qty: value }));
  };

  const handleOpenModal = () => {
    if (window.innerWidth <= 540) {
      handleSelectProduct(get(products, '0.id'));
      setOpenModal(!isOpenModal);
    }
  };

  const handleAddProduct = () => {
    const product = find(
      products,
      (product) => productSelectd.id === product.id
    );
    const data = {
      ...product,
      subCategory,
      qty: productSelectd.qty
    };
    alert.success(
      <>
        <Icon name="check-circle" /> เพิ่มสินค้าลงรถเข็นสำเร็จ
      </>
    );
    dispatch(addProduct(data));
  };

  const handleSubmit = () => {
    setProductSelectd({ id: '', qty: 1 });
    if (window.innerWidth <= 540) {
      handleOpenModal();
    } else {
      setIsDisableSubmit(true);
      handleAddProduct();
    }
  };

  const handleSubmitMobileSize = () => {
    handleAddProduct();
    setIsDisableSubmit(false);
    setProductSelectd({ id: '', qty: 1 });
    setOpenModal(false);
  };

  if (isEmpty(subCategory)) return '';

  const itemBreadcrumb = [
    {
      label: 'Home',
      to: '/'
    },
    {
      label: getContentWithLanguage(get(subCategory, 'category.name', {})),
      to: `/products/${get(subCategory, 'category._id', '')}`
    },
    {
      label: getContentWithLanguage(get(subCategory, 'name', {})),
      to: ''
    }
  ];

  return (
    <Style>
      <div className="container">
        <Breadcrumb items={itemBreadcrumb} />
        <div className="wrap-product-detail">
          <div className="preview-image">
            <Image src={get(subCategory, 'image')} />
          </div>
          <div className="product-detail">
            <h1>{getContentWithLanguage(subCategory.name)}</h1>
            <span className="price">
              {formatNumber(
                get(subCategory, 'price.amount'),
                get(subCategory, 'price.currency')
              )}
            </span>
            <p>{getContentWithLanguage(subCategory.description)}</p>
            <ul className="product-list">
              {map(products, (product) => {
                let price = product.price;
                let isale = false;
                if (!isEmpty(product.flashSalePrice)) {
                  isale = true;
                  price = product.flashSalePrice;
                }
                return (
                  <li key={product.id}>
                    <button
                      className={
                        product.id === get(productSelectd, 'id') ? 'active' : ''
                      }
                      onClick={() => handleSelectProduct(product.id)}
                    >
                      <span className="name">
                        {getContentWithLanguage(product.name)}
                      </span>
                      <div className="price">
                        {isale && (
                          <div className="old">
                            {formatNumber(
                              get(product, 'price.amount'),
                              get(product, 'price.currency')
                            )}
                          </div>
                        )}
                        <div className={`current ${isale ? 'sale' : ''}`}>
                          {formatNumber(
                            get(price, 'amount'),
                            get(price, 'currency')
                          )}
                        </div>
                      </div>
                    </button>
                  </li>
                );
              })}
            </ul>
            <div className="product-qty">
              <span>จำนวน</span>
              <ButtonQTY
                value={get(productSelectd, 'qty')}
                onChange={handleChangeQTY}
                disabled={isEmpty(get(productSelectd, 'id'))}
                size="lg"
              />
            </div>
            <div className="button-submit">
              <Button
                disabled={isDisableSubmit}
                onClick={handleSubmit}
                type="primary"
              >
                <Icon name="cart" />
                เพิ่มลงรถเข็น
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className={`modal-product-list ${isOpenModal ? 'active' : ''}`}>
        <button className="overlay" onClick={handleOpenModal} />
        <div className="content-modal">
          <ul className="product-list">
            {map(products, (product) => {
              let price = product.price;
              let isale = false;
              if (!isEmpty(product.flashSalePrice)) {
                isale = true;
                price = product.flashSalePrice;
              }
              return (
                <li key={product.id}>
                  <button
                    className={
                      product.id === get(productSelectd, 'id') ? 'active' : ''
                    }
                    onClick={() => handleSelectProduct(product.id)}
                  >
                    <span className="name">
                      {getContentWithLanguage(product.name)}
                    </span>
                    <div className="price">
                      {isale && (
                        <div className="old">
                          {formatNumber(
                            get(product, 'price.amount'),
                            get(product, 'price.currency')
                          )}
                        </div>
                      )}
                      <div className={`current ${isale ? 'sale' : ''}`}>
                        {formatNumber(
                          get(price, 'amount'),
                          get(price, 'currency')
                        )}
                      </div>
                    </div>
                  </button>
                </li>
              );
            })}
          </ul>
          <div className="product-qty">
            <span>จำนวน</span>
            <ButtonQTY
              value={get(productSelectd, 'qty')}
              onChange={handleChangeQTY}
              disabled={isEmpty(get(productSelectd, 'id'))}
            />
          </div>
          <div className="button-submit">
            <Button
              onClick={handleSubmitMobileSize}
              disabled={isEmpty(get(productSelectd, 'id'))}
              type="primary"
            >
              <Icon name="cart" />
              เพิ่มลงรถเข็น
            </Button>
          </div>
        </div>
      </div>
    </Style>
  );
};

ProductDetail.propTypes = {
  dispatch: PropTypes.func.isRequired,
  shopId: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
  match: PropTypes.shape().isRequired
};

const mapState = ({ app }) => ({
  shopId: get(app, 'shop.id', ''),
  customerId: get(app, 'customer.id', '')
});

export default connectRedux(mapState)(ProductDetail);
