import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import map from 'lodash/map';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';

import { useLanguage } from '../../hooks/language';
import { formatNumber, connectRedux } from '../../utils';
import { getSubCategory, getCategory } from '../../service';

import Catalog from '../../components/Catalog';
import ProductEmpty from '../../components/ProductEmpty';
import Breadcrumb from '../../components/Breadcrumb';

import { openLoading, hiddenLoading } from '../../components/Loading';

import Style from './style';

const Products = (props) => {
  const { shopId, customerId, match, history } = props;
  const categoryId = get(match, 'params.id', '');
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState({});

  const { getContentWithLanguage } = useLanguage();

  const initPage = async () => {
    openLoading();
    try {
      const body = {
        categoryId,
        shopId,
        customerId,
      };
      const res = await getSubCategory(body);
      const resCategory = await getCategory(shopId, categoryId);
      setCategory(resCategory);
      setProducts(res);
    } catch (err) {
      setProducts([]);
    }
    hiddenLoading();
  };

  useEffect(() => {
    if (categoryId !== '') initPage();
  }, []); // eslint-disable-line

  const handleSelectCategory = (value) => {
    history.push(`/products/detail/${value}`, { id: value });
  };

  if (isEmpty(products)) return <ProductEmpty />;

  const itemBreadcrumb = [
    {
      label: 'Home',
      to: '/',
    },
    {
      label: getContentWithLanguage(get(category, 'name', {})),
      to: '',
    },
  ];

  return (
    <Style>
      <div className="container">
        <Breadcrumb items={itemBreadcrumb} />
        <div className="row">
          {map(orderBy(products, ['sort'], ['asc']), (product) => {
            const id = get(product, 'id');
            return (
              <div key={id} className="D-3 T-4 SM-6 product-item ">
                <Catalog
                  name={getContentWithLanguage(product.name)}
                  image={product.image}
                  onClick={() => handleSelectCategory(id)}
                  price={formatNumber(
                    get(product, 'price.amount'),
                    get(product, 'price.currency')
                  )}
                />
              </div>
            );
          })}
        </div>
      </div>
    </Style>
  );
};

Products.propTypes = {
  shopId: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
  match: PropTypes.shape().isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapState = ({ app }) => ({
  shopId: get(app, 'shop.id', ''),
  customerId: get(app, 'customer.id', ''),
});

export default connectRedux(mapState)(Products);
