import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { connectRedux } from '../../utils';
import { getOrderQueue } from '../../service';
import { updateOrderQueue } from '../../actions/action';

import Style from './style';

const OrderQueue = ({ queue, customerId, dispatch }) => {
  const getCurrentQueue = async () => {
    try {
      const queue = await getOrderQueue(customerId);
      dispatch(updateOrderQueue(queue));
    } catch (err) {
      console.error(err);
    }
  };

  React.useEffect(() => {
    const myInterval = setInterval(getCurrentQueue, 30000);
    return () => {
      clearInterval(myInterval);
    };
    // eslint-disable-next-line
  }, []);

  const isTransferAt = get(queue, 'status', '') === 'IN_PROGRESS';
  return (
    <Style>
      {isTransferAt && (
        <div className="content">
          <div className="queue">
            <span className="label">คิวของคุณอยู่ลำดับที่</span>
            <div className="value">{get(queue, 'queue', '')}</div>
          </div>
          <div className="id">
            <span className="label">เลขออเดอร์ของคุณคือ</span>
            <span>{get(queue, 'orderId', '')}</span>
          </div>
        </div>
      )}
      {!isTransferAt && (
        <div className="content">
          <p>
            คุณสามารถตรวจสอบคิวของคุณได้หลังจากที่ทำการโอนเงินแล้ว
            โดยการเข้าเช็คผ่านปุ่ม go to shop อีกครั้ง <br />
            หลังพนักงานทำการเช็คยอดให้เรียบร้อยแล้วนะคะ ขอบคุณค่ะ
          </p>
        </div>
      )}
    </Style>
  );
};

OrderQueue.propTypes = {
  queue: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
  customerId: PropTypes.string
};

OrderQueue.defaultProps = { customerId: '' };

const mapState = ({ app }) => ({
  queue: app.queue,
  customerId: get(app, 'customer.id')
});

export default connectRedux(mapState)(OrderQueue);
