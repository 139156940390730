import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
// import Select from 'react-select';

import map from 'lodash/map';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';

import { useLanguage } from '../../hooks/language';

import { connectRedux } from '../../utils';
import { getCategory, getBanner } from '../../service';
import Catalog from '../../components/Catalog';
import ProductEmpty from '../../components/ProductEmpty';
import Banner from './Banner';
import FlashSale from '../../containers/FlashSale';
import { openLoading, hiddenLoading } from '../../components/Loading';

import Style from './style';

const Home = (props) => {
  const { shopId } = props;
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [banner, setBanner] = useState([]);

  const { getContentWithLanguage } = useLanguage();

  const initPage = async () => {
    openLoading();
    try {
      const resBanner = await getBanner(shopId);
      setBanner(resBanner);
      const res = await getCategory(shopId);
      setCategories(res);
    } catch (err) {
      setCategories([]);
    }
    hiddenLoading();
  };

  useEffect(() => {
    if (shopId) initPage();
  }, [shopId]); // eslint-disable-line

  const handleSelectCategory = (value) => {
    history.push(`/products/${value}`, { id: value });
  };

  if (isEmpty(categories)) return <ProductEmpty />;

  return (
    <Style>
      <div className="container wrap-banner">
        <Banner items={banner} />
      </div>
      <div className="container flash-sale">
        <FlashSale />
      </div>
      <div className="container">
        <div className="wrap-category">
          <div className="header">
            <span>สินค้า</span>
          </div>
          <div className="row">
            {map(orderBy(categories, ['sort'], ['asc']), (category) => {
              const id = get(category, '_id');
              return (
                <div key={id} className="D-3 T-4 SM-6 catalog-item ">
                  <Catalog
                    name={getContentWithLanguage(category.name)}
                    image={category.image}
                    onClick={() => handleSelectCategory(id)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Style>
  );
};

Home.propTypes = {
  // dispatch: PropTypes.func.isRequired
  shopId: PropTypes.string.isRequired
};

const mapState = ({ app }) => ({ shopId: app.shop.id });

export default connectRedux(mapState)(Home);
