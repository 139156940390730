import styled from '@emotion/styled';

export default styled.div`
  label: product-empty;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 540px) {
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0, 0);
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .wrap-content {
    display: inline-block;
    text-align: center;
    color: #4a4a4a;
    .message {
      font-family: 'Prompt', sans-serif;
      font-size: 20px;
      font-weight: 500;
      margin-top: 10px;
    }
  }
`;
