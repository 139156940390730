import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal-es';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { closeModal, openModal } from '../../utils/modal';
import { formatNumber, alert } from '../../utils';

import Icon from '../Icon';
import IconButton from '../IconButton';
import Button from '../Button';
import Style from './style';

import { validateCoupon } from '../../service';

const ModalDiscount = (props) => {
  const { name, value, onChange, data } = props;

  const [currentValue, updateValue] = useState('');

  const handleSubmit = async () => {
    const body = {
      code: currentValue,
      shopId: get(data, 'shopId'),
      customerId: get(data, 'customerId'),
      products: get(data, 'products', []).map((product) => {
        return {
          id: get(product, 'id'),
          qty: get(product, 'qty'),
          price: get(product, 'price.amount')
        };
      })
    };
    try {
      const res = await validateCoupon(body);
      onChange(res);
      closeModal(name);
      updateValue('');
    } catch (err) {
      console.log('err >>>', err);
      alert.error('ไม่สามารถใช้ส่วนลดนี้ได้เพราะไม่ตรงตามเงื่อนไข');
    }
  };

  const handleRemoveDiscount = () => {
    onChange({});
  };

  const isPreview = !isEmpty(value);

  return (
    <>
      {isPreview ? (
        <div className="button-preview">
          <div className="discount-info">
            <span className="ellipsis">{get(value, 'name')}</span>
            <div className="price">
              - {formatNumber(get(value, 'price'), 'th')}
            </div>
          </div>
          <IconButton
            name="trash"
            type="danger"
            onClick={handleRemoveDiscount}
          />
        </div>
      ) : (
        <Button onClick={() => openModal(name)}>
          <Icon name="tag" /> เพิ่มโค้ดส่วนลด
        </Button>
      )}
      <Modal name={name} center zIndex={99}>
        <Style>
          <div className="title">
            <span>ส่วนลด</span>
            <IconButton
              size="lg"
              name="times"
              onClick={() => closeModal(name)}
            />
          </div>
          <input
            value={currentValue}
            onChange={(e) => updateValue(e.target.value)}
            name="discount"
            placeholder="กรุณาพิมพ์ข้อความ"
            autoFocus
          />
          <Button
            className="button-submit"
            type="primary"
            onClick={handleSubmit}
          >
            <Icon name="tag" /> ใช้โค้ด
          </Button>
        </Style>
      </Modal>
    </>
  );
};

ModalDiscount.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.shape(),
  onChange: PropTypes.func,
  value: PropTypes.shape()
};

ModalDiscount.defaultProps = {
  onChange: () => null,
  value: {},
  data: {}
};

export default ModalDiscount;
