import styled from '@emotion/styled';

export default styled.div`
  label: modal-confirm;
  font-family: 'Prompt', sans-serif;
  width: 450px;
  border-radius: 20px;
  background-color: var(--color-white);
  padding: 20px;
  @media (max-width: 540px) {
    width: 300px;
  }
  > .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > span {
      font-size: 20px;
      font-weight: 500;
      color: var(--color-secondary);
    }
    > button > i{
      font-size: 20px;
    }
  }
`;
