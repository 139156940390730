import get from 'lodash/get';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';

import * as ActionType from './actionType';
import { openLoading, hiddenLoading } from '../components/Loading';

import {
  getShop,
  getCustomer,
  createOrder,
  shopIsOpen,
  getOrderQueue
} from '../service';
import { alert } from '../utils';

const initApp = (value) => {
  return async (dispatch) => {
    try {
      const data = window.atob(value);
      const { customerId, expire, isAdmin } = JSON.parse(data);
      const toDay = moment(moment().tz('Asia/Bangkok').format());
      const isExpire = moment(expire).diff(toDay) <= 0;
      if (isExpire) {
        alert.error(
          'ปุ่มร้านค้าหมดอายุ กรุณาติดต่อเพื่อขอปุ่มร้านค้าใหม่อีกครั้ง'
        );
        hiddenLoading();
      } else {
        const customer = await getCustomer(customerId);
        const shop = await getShop(customer.shop);
        const queue = await getOrderQueue(customer.id);
        dispatch({
          type: ActionType.INITAPP,
          shop,
          customer,
          shopBy: isAdmin ? 'admin' : 'user',
          queue
        });
      }
    } catch (err) {
      console.log('error', err);
      hiddenLoading();
      dispatch({
        type: ActionType.INITAPP,
        shop: {},
        customer: {},
        queue: {}
      });
    }
  };
};

const addProduct = (data) => ({
  type: ActionType.PRODUCTS_ADD,
  data
});

const updateProductQTY = (product, value) => ({
  type: ActionType.PRODUCTS_UPDATE_QTY,
  product,
  value
});

const removeProduct = (product) => ({
  type: ActionType.PRODUCTS_REMOVE,
  product
});

const updateOrder = (value, name) => ({
  type: ActionType.ORDER_UPDATE,
  value,
  name
});

const submitOrder = (callback) => {
  return async (dispatch, getState) => {
    openLoading();
    const { orders, app } = getState();
    let totalAmount = 0;
    const products = [];
    let currency = '';
    forEach(orders.products, (value) => {
      let price = value.price;
      let isSale = false;
      if (!isEmpty(value.flashSalePrice)) {
        price = value.flashSalePrice;
        isSale = true;
      }
      totalAmount += value.qty * price.amount;
      products.push({
        id: value.id,
        qty: value.qty,
        price: price.amount,
        isSale
      });
      currency = price.currency;
    });

    const body = {
      shopId: get(app, 'shop.id'),
      customerId: get(app, 'customer.id'),
      streamerId: get(orders, 'steamer.id', ''),
      totalAmount,
      currency,
      products,
      note: get(orders, 'note', ''),
      discount: get(orders, 'discount', ''),
    };
    try {
      const shopOpen = await shopIsOpen(get(app, 'shop.id'), get(app, 'shopBy', 'user'));
      if (get(shopOpen, 'status') !== 'close') {
        const res = await createOrder(body);
        if (res.id) {
          alert.success(`ยืนยันการสั่งซื้อเลขที่ ${res.id}`);
          dispatch({
            type: ActionType.SUBMIT_ORDER_SUCCAESS,
            data: res
          });
          if (callback) callback();
        } else {
          alert.error(res.error);
        }
      } else {
        alert.error(get(shopOpen, 'message'));
      }
    } catch (err) {
      console.log('error', err);
      alert.error(err);
    }
    hiddenLoading();
  };
};

const updateOrderQueue = (queue) => ({
  type: ActionType.ORDER_QUEUE,
  queue
});

export {
  initApp,
  addProduct,
  updateProductQTY,
  removeProduct,
  updateOrder,
  submitOrder,
  updateOrderQueue
};
