import styled from '@emotion/styled';

export default styled.div`
  label: modal-note-content;
  font-family: 'Prompt', sans-serif;
  width: 450px;
  border-radius: 20px;
  background-color: var(--color-white);
  padding: 20px;
  @media (max-width: 540px) {
    width: 300px;
  }
  > .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > span {
      font-size: 20px;
      font-weight: 500;
      color: var(--color-secondary);
    }
    > button > i{
      font-size: 20px;
    }
  }
  textarea {
    margin-top: 20px;
    margin-bottom: 20px;
    height: 150px;
    border-radius: 10px;
    background-color: var(--color-light-grey);
    display: block;
    width: 100%;
    outline: none;
    border: none;
    padding: 10px;
    color: var(--color-secondary);
    font-weight: 300;
    resize: vertical;
  }
  .button-submit {
    display: block;
    height: 50px;
    width: 100%;
  }
`;
