import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';
import Style from './style';

const IconButton = (props) => {
  const { type, onClick, disabled, className, style, name, size } = props;
  return (
    <Style
      style={style}
      disabled={disabled}
      className={`${type} ${size} ${className}`}
      onClick={(e) => {
        if (!disabled) onClick(e);
      }}
    >
      <Icon name={name} />
    </Style>
  );
};

IconButton.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.shape(),
  type: PropTypes.oneOf(['default', 'primary', 'danger']),
  size: PropTypes.oneOf(['sm', 'lg']),
};

IconButton.defaultProps = {
  disabled: undefined,
  onClick: () => null,
  type: 'default',
  className: '',
  style: undefined,
  size: 'sm'
};

export default IconButton;
