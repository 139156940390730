import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal-es';
import { closeModal } from '../../utils/modal';

import IconButton from '../IconButton';

import Style from './style';

const ModalConfirm = (props) => {
  const { name, title, children } = props;
  return (
    <Modal name={name} center zIndex={99}>
      <Style>
        <div className="title">
          <span>{title}</span>
          <IconButton
            size="lg"
            name="times"
            onClick={() => closeModal(name)}
          />
        </div>
        <div className="children">{children}</div>
      </Style>
    </Modal>
  );
};

ModalConfirm.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PropTypes.any,
};

ModalConfirm.defaultProps = {
  title: '',
  children: '',
};

export default ModalConfirm;
