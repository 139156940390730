import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

import Style from './style';

const Breadcrumb = (props) => {
  const { items } = props;
  return (
    <Style>
      <ul>
        {map(items, (item, index) => {
          return (
            <li key={index}>
              {isEmpty(item.to) ? item.label : <Link to={item.to}>{item.label}</Link>}
            </li>
          );
        })}
      </ul>
    </Style>
  );
};

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      to: PropTypes.string,
    })
  ).isRequired,
};

Breadcrumb.defaultProps = {};

export default Breadcrumb;
