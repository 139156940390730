import styled from '@emotion/styled';

export default styled.div`
  label: breadcrumb;
  margin-bottom: 30px;
  display: block;
  @media (max-width: 540px) {
    margin-bottom: 20px;
  }
  ul {
    padding: 2px 20px;
    border-radius: 20px;
    background-color: #f5f5f5;
    display: inline-block;
    @media (max-width: 540px) {
      display: block;
    }
    > li {
      display: inline-block;
      color: #4a4a4a;
      padding: 10px 4px;
      font-family: Prompt;
      font-size: 14px;
      font-weight: 500;
      &:after {
        content: '/';
        padding-left: 8px;
        color: #cccccc;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      > a {
        color: var(--color-primary);
      }
    }
  }
`;
