import styled from '@emotion/styled';

export default styled.div`
  label: FlashSale;
  margin-bottom: 37px;
  > .title {
    display: flex;
    justify-content: space-between;
    > span {
      font-family: Prompt;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
      margin-right: 20px;
    }
    > button {
      background: none;
      outline: none;
      border: none;
      font-family: Prompt;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #0097c1;
    }
  }
  > .wrap-item > ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 -15px;
    @media (max-width: 540px) {
      justify-content: center;
    }
    > li {
      margin-top: 20px;
      margin-right: 15px;
      margin-left: 15px;
      display: flex;
      align-items: flex-start;
      padding: 10px;
      border-radius: 20px;
      background-color: #f5f5f5;
      max-width: 370px;
      @media (max-width: 425px) {
        max-width: 100%;
        width: 100%;
        justify-content: space-between;
      }
      > .img {
        max-width: 130px;
        max-height: 130px;
        margin-right: 10px;
        border-radius: 20px;
        overflow: hidden;
      }
      > .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        .name {
          font-family: Prompt;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          color: #4a4a4a;
          margin-bottom: 6px;
        }
        .price {
          display: block;
          font-family: Prompt;
          font-size: 16px;
          font-weight: 500;
          color: #e02020;
          height: 100%;
          .old {
            font-size: 12px;
            color: rgba(74, 74, 74, 0.7);
            text-decoration: line-through;
          }
        }
        .wrap-countdown {
          display: flex;
          align-items: center;
          > a {
            border-radius: 10px;
            box-shadow: 0 4px 15px -5px #0097c1;
            background-color: #0097c1;
            border: 1px solid #0097c1;
            height: 43px;
            width: 40px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            text-decoration: none;
          }
          ul.countdown {
            display: flex;
            align-items: center;
            border-radius: 10px;
            background-color: #e5e5e5;
            margin-right: 9px;
            padding: 5px 0;
            > li {
              position: relative;
              margin: 0 6px;
              display: block;
              .value {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 2px 5px 1px 6px;
                border-radius: 5px;
                background-color: #333;
                width: 28px;
                height: 24px;
                color: #fff;
              }
              .label {
                display: block;
                text-align: center;
                font-family: Prompt;
                font-size: 6px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #4a4a4a;
              }

              &:after {
                content: ':';
                position: absolute;
                right: -8px;
                top: 2px;
                color: #4a4a4a;
              }
              &:last-child:after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
`;
