import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';

import Style from './style';

const ButtonQTY = (props) => {
  const [currentValue, setCurrentValue] = useState(1);
  const { disabled, size, value, onChange } = props;

  React.useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleRemove = () => {
    if (currentValue > 1) {
      setCurrentValue(Number(currentValue) - 1);
      onChange(Number(currentValue) - 1);
    }
  };

  const handleAdd = () => {
    setCurrentValue(Number(currentValue) + 1);
    onChange(Number(currentValue) + 1);
  };

  const handleInputChange = (e) => {
    const valueInput = e.target.value;
    if (
      (/^[0-9]+$/.test(valueInput) || valueInput === '')
      && Number(e.target.value) <= 9999
    ) {
      const numberValue = Number(e.target.value);
      setCurrentValue(numberValue);
      onChange(numberValue);
    }
  };

  const handleInputBlur = (e) => {
    const valueInput = e.target.value;
    if (Number(valueInput) <= 0) {
      setCurrentValue(1);
      onChange(1);
    }
  };

  return (
    <Style disabled={disabled} className={size}>
      <button disabled={currentValue === 1} onClick={handleRemove}>
        <Icon name="minus" />
      </button>
      <input
        type="text"
        value={currentValue}
        onBlur={handleInputBlur}
        onChange={handleInputChange}
      />
      <button onClick={handleAdd}>
        <Icon name="plus" />
      </button>
    </Style>
  );
};

ButtonQTY.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.number,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'lg'])
};

ButtonQTY.defaultProps = {
  disabled: false,
  value: undefined,
  size: 'sm',
  onChange: () => null
};

export default ButtonQTY;
