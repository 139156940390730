import styled from '@emotion/styled';

export default styled.button`
  label: button;
  display: inline-block;
  font-family: 'Prompt', sans-serif;
  text-align: center;
  padding: 6px 16px;
  font-size: 16px;
  border-radius: 4px;
  outline: none;
  transition: all 0.4s ease-in-out;
  transform: scale(1);
  border-radius: 10px;
  background: var(--color-white);
  border: none;
  color: var(--color-secondary);
  box-shadow: 0 4px 10px -5px rgba(0, 0, 0, 0.2);
  height: 40px;
  min-width: 150px;
  &:hover {
    color: var(--color-primary);
  }
  &:active {
    transform: scale(0.8);
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &.primary {
    background: var(--color-primary);
    color: var(--color-white);
    box-shadow: 0 4px 15px -5px var(--color-primary);
    height: 50px;
    &:hover {
      background-color: var(--color-primary-hover);
    }
  }
  &.danger {
    height: 50px;
    background: var(--color-danger);
    color: var(--color-white);
    box-shadow: 0 4px 15px -5px var(--color-danger);
    &:hover {
      background-color: var(--color-danger-hover);
    }
  }
`;
