import toNumber from 'lodash/toNumber';
import isArray from 'lodash/isArray';
import head from 'lodash/head';
import last from 'lodash/last';

export { default as connectRedux } from './connectRedux';
export { default as alert } from './alert';

const numberFormat = (value) => {
  // Intl.NumberFormat().format(toNumber(value))
  return toNumber(value)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const formatNumber = (value, currency) => {
  let valueFormat = value;
  if (isArray(value)) {
    valueFormat = `${numberFormat(head(value))} - ${numberFormat(last(value))}`;
  } else {
    valueFormat = numberFormat(value);
  }

  switch (currency) {
    case 'THB': {
      return `฿ ${valueFormat}`;
    }
    default: {
      return valueFormat;
    }
  }
};

export { formatNumber };
