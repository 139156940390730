import styled from '@emotion/styled';

export default styled.div`
  label: order-queue;
  position: fixed;
  z-index: 999;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  > .content {
    font-size: 16px;
    > div {
      margin-bottom: 20px;
    }
    .queue .value {
      font-size: 60px;
      margin-top: 20px;
    }
    p {
      padding: 0 20px;
      font-size: 18px;
      line-height: 1.5;

      @media (max-width: 540px) {
        font-size: 16px;
        br {
        display: none;
      }
      }
    }
    .id {
      span {
        font-size: 14px;
      }
      .label {
        margin-right: 10px;
        @media (max-width: 540px) {
          margin-right: 0;
        }
      }
    }
    span {
      @media (max-width: 540px) {
        display: block;
        margin-bottom: 8px;
        text-align: center;
      }
    }
  }
`;
