import styled from '@emotion/styled';

export default styled.div`
  label: page-product-detail;
  font-family: 'Prompt', sans-serif;
  > .container {
    max-width: 786px;
    @media (max-width: 540px) {
      flex-direction: column;
      align-items: center;
    }
  }
  .wrap-product-detail {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    @media (max-width: 540px) {
      flex-direction: column;
      align-items: center;
    }
  }
  .preview-image {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 30px;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.2);
    background-color: var(--color-white);
    border-radius: 20px;
    max-width: 336px;
    max-height: 336px;
    overflow: hidden;
    @media (max-width: 540px) {
      margin-right: 0px;
      width: 100%;
      justify-content: center;
    }
  }
  .product-detail {
    max-width: 450px;
    margin-bottom: 100px;
    width: 100%;
    @media (max-width: 540px) {
      margin-top: 20px;
    }
    h1 {
      font-size: 24px;
      font-weight: 500;
      color: var(--color-secondary);
      margin-bottom: 10px;
    }
    .price {
      font-size: 16px;
      font-weight: 300;
      color: var(--color-secondary);
      .old {
        font-size: 12px;
        color: rgba(74, 74, 74, 0.7);
        text-decoration: line-through;
      }
      .sale {
        color: #e02020;
      }
    }
    p {
      font-size: 16px;
      line-height: 1.25;
      font-weight: 300;
      color: var(--color-secondary);
      opacity: 0.7;
      margin-top: 12px;
      margin-bottom: 30px;
    }
  }

  .button-submit {
    @media (max-width: 540px) {
      margin-top: 20px;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background: var(--color-white);
      z-index: 2;
      padding: 14px 120px 14px 20px;
      box-shadow: 0 -5px 20px 0 rgba(0, 0, 0, 0.2);
    }
    > button {
      display: block;
      width: 100%;
      text-align: center;
      @media (max-width: 540px) {
        max-width: 240px;
      }
    }
  }

  ul.product-list {
    border-radius: 20px;
    background-color: var(--color-light-grey);
    padding: 10px;
    margin-bottom: 30px;
    @media (max-width: 540px) {
      display: none;
    }
    li {
      list-style-type: none;
      display: block;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
    li > button {
      font-family: 'Prompt', sans-serif;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 50px;
      padding: 10px 20px;
      border: none;
      border-radius: 10px;
      box-shadow: 0 4px 9px -5px rgba(0, 0, 0, 0.2);
      background-color: var(--color-white);
      outline: none;
      font-size: 14px;
      font-weight: 500;
      .name {
        text-align: left;
      }
      .price {
        font-size: 16px;
        font-weight: 500;
        color: var(--color-secondary);
        white-space: nowrap;
        margin-left: 10px;
        .old {
          font-size: 12px;
          color: rgba(74, 74, 74, 0.7);
          text-decoration: line-through;
        }
        .sale {
          color: #e02020;
        }
      }
      &.active {
        color: var(--color-primary);
        box-shadow: 0 4px 9px -5px var(--color-primary);
        border: solid 2px var(--color-primary);
        .price {
          color: var(--color-primary);
        }
      }
    }
  }
  .product-qty {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    border-radius: 20px;
    background-color: var(--color-light-grey);
    padding: 10px 20px;
    margin-bottom: 30px;
    > span {
      font-size: 16px;
      font-weight: 500;
    }
    @media (max-width: 540px) {
      display: none;
    }
  }
  .modal-product-list {
    display: flex;
    align-items: flex-end;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    z-index: 91;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s ease-in-out;
    > .overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 1;
      outline: none;
      border: none;
      display: block;
      opacity: 0;
    }
    > .content-modal {
      position: relative;
      z-index: 2;
      background: var(--color-light-grey);
      width: 100%;
      border-radius: 20px 20px 0 0;
      display: flex;
      flex-direction: column;
      transform: translateY(100%);
      transition: all 0.4s ease-in-out;
    }

    @media (max-width: 540px) {
      &.active {
        opacity: 1;
        visibility: visible;
        > .content-modal {
          transform: translateY(0px);
        }
        > .overlay {
          opacity: 1;
        }
      }
      > .content-modal .product-list {
        display: block;
        margin-bottom: 0;
      }
      > .content-modal .product-qty {
        display: flex;
        height: auto;
        margin: 30px 0;
      }
    }

    .content-modal > ul.product-list {
      max-height: calc(100vh - 270px);
      overflow: auto;
    }
    .button-submit {
      position: relative;
      background: none;
      padding: 0 20px;
      box-shadow: none;
      margin: 0 0 10px 0;
      @media (max-width: 540px) {
        > button {
          display: block;
          max-width: 100%;
        }
      }
    }
  }
`;
