import { createModal } from 'react-modal-es';

const customUIComponent = (props) => (
  props.children
);

const configs = {
  customUI: customUIComponent,
};
const modal = createModal(configs);
export const openModal = modal.openModal;
export const closeModal = modal.closeModal;
export const closeAllModal = modal.closeAllModal;
export const ModalProvider = modal.ModalProvider;
